export function getPartners() {
    return [
        {
            id: 1,
            image: "/assets/images/1st.jpg",
            url: "URL1"
        },
        {
            id: 2,
            image: "/assets/images/2nd.jpg",
            url: "URL2"
        },
        {
            id: 3,
            image: "/assets/images/3rd.jpg",
            url: "URL3"
        },
        {
            id: 4,
            image: "/assets/images/4th.jpg",
            url: "URL4"
        },
        {
            id: 5,
            image: "/assets/images/5th.jpg",
            url: "URL5"
        },
        {
            id: 6,
            image: "/assets/images/6th.jpg",
            url: "URL6"
        }
    ];
}