import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
    .use(initReactI18next)
    .init({
        resources: {
            EN: {
                translation: {
                    'Membership_Content1': '',
                    'LinkToHKQAA':'https://www.hkqaa.org/en_index.php',
                    'HKQAAPersonnelSeries':'https://www.hkqaa.org/en_regservice.php?catid=11&id=15',
                    "URL1":"http://www.hkqaa.org/en_gsservice.php?catid=7&id=9",
                    "URL2":"https://srr.hkqaa.org/",
                    "URL3":"https://hkwr.hkqaa.org/",
                    "URL4":"http://www.hkqaa.org/en_abthkqaa.php?catid=5",
                    "URL5":"http://www.hkqaa.org/en_community.php?catid=3",
                    "URL6":"http://www.hkqaa.org/en_certservice.php?catid=26",
                }
            },
            TC: {
                translation: {
                    ' ': '',
                    //Layout.js
                    'Home': '主頁', //Not Confirm
                    'Insights and Trends': '趨勢與前瞻',
                    'Courses': '課程',
                    'Trainer / Academy': '導師/學院',
                    'Association': '協會組織',
                    'Membership': '用戶類別',
                    'About Us': '關於我們',
                    'Personnel Registration': '人員註冊',
                    'Contact Us': '聯絡我們',
                    'Login': '登入',
                    'Log In': '登入',
                    'Sign Up': '登記',
                    'Tel': '電話',
                    'Address: 19/F., K. Wah Centre, 191 Java Road, North Point, Hong Kong': '地址: 香港北角渣華道191號嘉華國際中心19樓',
                    'Email': '電郵',
                    'Subscribe our news': '訂閱最新消息',
                    'Terms & Condition': '條款和條件',
                    'Terms And Condition': '條款和條件',
                    'Privacy Policy': '隱私和政策',
                    //ProfileLayout.js
                    'Log out': '登出',
                    'Welcome back': '歡迎回來,',
                    //Copyright.js
                    'Copyright': 'Copyright',
                    'Hong Kong Quality Assurance Agency.': '香港品質保證局',
                    'All rights reserved.': '版權所有 不得轉載',
                    //Home
                    'LIGHTSHARE': 'LightShare「分享易」',
                    'KNOWLEDGE EXCHANGE PLATFORM': '知識交流平台',
                    'INSPIRING': '啟迪視野',
                    'SHARING': '知識分享',
                    'NETWORKING': '互動連結',
                    'EXPLORE MORE': '查看更多',
                    'Insights And Trends': '趨勢與前瞻',
                    'Stay ahead of megatrends with visionary perspectives': '開拓宏遠視野，走在大趨勢前端',
                    //
                    'DISCOVER MORE': '查看更多',
                    'Search': '搜尋',
                    'Profile / Spotlight': '簡介/焦點',
                    'Pick the potential trainer/academy and review their offer': '選擇合適您的導師/學院，並查看相關的課程',
                    'Pick the potential association and review their offer': '選擇合適您的協會組織，並查看相關的課程', 
                    'How We Work?': '如何運作？',
                    'Let\'s get started.': '我們馬上開始',
                    'Individual Member': '個人用戶',
                    'Can through this platform to join the hottest topic training and receive the insight and trends message. Enhancing of the competence with timeless plan.': '可透過 LightShare平台參與最新和熱門的培訓課程，提升個人競爭力。',
                    'Organization Member': '機構用戶',
                    'Can establish their own E-Learning Platform for supporting their own staff to enhance the competence and skill with timeless plan. They can select the training topic from the platform or use their own training material (Video) to upload to the platform and share to the specific group of staff then management with training analysis report.': '可透過 LightShare平台建立機構內部的網上培訓平台，LightShare提供不同主題的培訓內容可供選擇，而機構更可上載自家量身定制的培訓課程和短片，通過平台一站式提供培訓予員工及管理培圳記錄。',
                    'Trainer and Academy Member': '導師/學院用戶',
                    'Can through this platform to share & promote their training course, also this platform is providing the participant analysis report.': '可透過 LightShare平台分享和推廣培訓課程。',
                    'Association Member': '協會組織用戶',
                    'Can through this platform to share & promote their training course to their specific member, also this platform is providing the participant analysis report.': '可透過LightShare平台向他們的特定會員分享和推廣他們的培訓課程，通過平台也提供參與者分析報告。',
                    'Joining the course': '參加課程',
                    'Step: 1': '第一步',
                    'Registration': '登記成為用戶',
                    'Click the link and create your username & password then you will become a member right away.': '點擊鏈結即可建立您的用戶名稱和密碼，您將立即成為會員。',
                    'Step: 2': '第二步',
                    'Select the course': '選擇課程',
                    'Select the interesting course and apply it anytime, anywhere.': '隨時隨地選擇及申請感興趣的課程。',
                    'Step: 3': '第三步',
                    'Receive the token': '收到網上代幣',
                    'Trainer will assign the token number for you to join the specific course.': '選擇您感興趣的課程，隨時隨地應用。',
                    'Step: 4': '第四步',
                    'Join the course': '參加課程',
                    'Member can join the course.': '會員可以參與課程。',
                    'Inspiring, Sharing and Networking': '啟迪視野，知識分享，互動連結',
                    'Sign up free today!': '立即免費登記',
                    'SIGN UP': '登記',
                    'HKQAA Services and Campaigns': '香港品質保證局的服務和活動',
                    //Trainer/Academy
                    'Highlight Trainer / Academy': '主導師/焦點學院',
                    'Latest Trainer / Academy': '最新導師/學院',
                    //Membership 
                    'Lightshare Membership': 'LightShare「分享易」用戶類型',
                    'Lightshare platform is offering four different finds of memberships to our society, which included': 'LightShare「分享易」平台提供四種不同的用戶類型，其中包括:',
                    'Membership_Content1': '參與者透過參與',
                    'Individual member': '個人用戶',
                    'is supporting you to enhance your personal knowledge & social network,': '，增進個人知識和擴大社交網絡。平台不僅支援',
                    'Organization membership': '機構用戶',
                    'is supporting all kind of organization to enhance their staff competence and knowledge also the organization can through this platform to share their knowledge and technology to the potential partner.': '提升内部員工的個人能力和知識，機構用戶更可通過這個平台與潛在合作夥伴分享他們的知識和技術。',
                    'Trainer & Academy membership': '導師/學院',
                    'is supporting the individual trainer or academy through the lightshare platform to reach their potential client. It is low-cost tools to enhancing their business performance during the pandemic period.': '通過平台聯繫潛在客戶，全方位及成本相宜地提升其業務績效。',
                    'Association membership': '協會用戶',
                    'is going to support the industry association to share their industry news or knowledge to the stakeholder.': '可與行業協會向持分者分享其行業新聞或知識。',
                    'Individual Membership': '個人用戶',
                    'Can through this platform to join the hottest topic training and receive the insight and trends message. Enhancing of the competence with timeless plan.': '可透過 LightShare「分享易」平台一起參與熱門的網上培訓課程，掌握最新市場趨勢和關鍵資訊，隨時隨地提升個人競爭力。',
                    'Apply Now': '立即申請',
                    'Organization Membership': '機構用戶',
                    'Can establish their own E-Learning Platform for supporting their own staff to enhance the competence and skill with timeless plan. They can select the training topic from the platform or use their own training material (Video) to upload to the platform and share to the specific group of staff then management with training analysis report.': '可透過 LightShare「分享易」平台建立機構內部的網上培訓平台。機構可選擇平台上不同主題的培訓內容，更可上載自家量身定制的培訓課程和短片，通過平台一站式服務提供培訓給予員工及獲得管理培訓記錄。',
                    'Trainer/Academy Membership': '導師/學院用戶',
                    'Can through this platform to share & promote their training course, also this platform is providing the participant analysis report.': '可透過LightShare「分享易」平台分享及推廣培訓課程給予登記用戶，配以參與者分析報告。',
                    'Coming Soon': '即將推出',
                    'Association Membership': '協會組織用戶',
                    'Can through this platform to share & promote their training course to their specific member, also this platform is providing the participant analysis report.': '可透過LightShare「分享易」平台分享及推廣培訓課程給予登記用戶，配以參與者分析報告',
                    //About Us
                    'About LightShare': '關於LightShare「分享易」',
                    'Through the LightShare, you are also able to become a part of the meta-networking ecosystem, which enables you to extend your business network and raise market awareness across regions, and build your socially responsible image in the communities.': '通過這平台，你還可以成為元網絡生態系統的一部分，助你跨越地域及界別限制，擴展業務網絡和增加市場知名度，提升社會責任形象。',
                    'You are welcome to join us as a member of "LightShare" and explore the tremendous development opportunities together!': '歡迎您加入成為“LightShare”的登記用戶，共同探索巨大的發展機遇！',
                    'About HKQAA': '關於香港品質保證局',
                    'Through knowledge sharing and technology transfer, we help industries enhance their management performance. We also provide innovative and professional services to support enterprises and society to address future challenges and foster sustainable development.': '我們通過知識共享和技術轉換，幫助行業提高管理績效。我們還提供創新和專業服務，支持企業和社會應對未來挑戰，促進可持續發展。',
                    'Established in 1989 as a non-profit public organisation by the Hong Kong Government, HKQAA is committed to promoting good management practices.': '香港品質保證局是由香港政府於一九八九年成立的非牟利機構，致力於推廣良好的管理方法。',
                    'HKQAA is devoted to creating value for enterprises and making contributions to shaping a better environment and society. We have developed a wide range of pioneering services to meet the needs of industry and society. These services are in the areas of social responsibility, environmental protection, energy management, carbon neutrality, green and sustainable finance, and barrier free accessibility management.': 'HKQAA致力於為企業創造價值，並為創造更好的環境和社會作出貢獻。我們開發了一系列創性服務，以滿足行業和社會的需求。這些服務涉及社會責任、環境保護、能源管理、碳中和、綠色和可持續金融以及無障礙管理等領域。',
                    //Personnel Registration Service ----Not Confirm
                    'Personnel Registration Service': '人員註冊服務',
                    '"LightShare" is a pioneering knowledge-exchange online platform established by the Hong Kong Quality Assurance Agency (HKQAA).': 'LightShare「分享易」由香港品質保證局建立，是一個領先創新的網上知識交流平台。',
                    'This platform brings together renowned organisation leaders, management professionals and industry experts to exchange experience, and share their international perspectives and invaluable knowledge with everyone in the world to facilitate capacity building and talent development.': '平台匯萃知名機構領袖、管理專才和行業專家，互相交流心得，並與世界各地人士分享國際視野和寶貴知識，促進人才及技能培訓。',
                    'You are welcome to join us as a member of "LightShare" and explore the tremendous development opportunities together!': '歡迎您加入成為“LightShare”的登記用戶，共同探索巨大的發展機遇！',
                    //Contact Us ----Not Confirm
                    'If you have any enquiries, please complete the below e-form. We will be contacting you later': '如有任何查詢，請填寫以下網上表格，我們將會與您聯絡。',
                    'Title': '稱謂',
                    'First Name': '名字',
                    'Last Name': '姓氏',
                    'Email': '電郵',
                    'Country/Region': '國家/地區',
                    'Message': '信息',
                    'Submit': '提交',
                    'Office Addresses': '各地辦公地址',
                    'Hong Kong': '香港',
                    'Hong Kong Quality Assurance Agency': '香港品質保證局',
                    '19 / F., K. Wah Centre, 191 Java Road,': '香港北角',
                    'North Point, Hong Kong': '渣華道191號嘉華國際中心19樓',
                    'Guangzhou': '廣州',
                    'HKQAA Certification (Shanghai) Ltd.': '標準認證服務(上海)有限公司',
                    'Guangzhou Branch': '廣州分公司',
                    '22 / F., Nova Tower, No. 185 Yue Xiu Road South,': '中國廣州市',
                    'Guangzhou, China.': '越秀南路185號創舉商務大廈22樓',
                    'Postal Code: 510100': '郵編：510100',
                    'Xi’an': '西安',
                    'HKQAA Certification (Shanghai) Ltd.': '標準認證服務(上海)有限公司',
                    'Xian Representative Office': '西安辦事處',
                    'Room 1608, 16/F., New World, 70 Weibin Road,': '中國陝西省西安市',
                    'Weiyang District, Xi’an City, Shanxi Province, China': '未央區渭濱路70號水晶新天地16樓1608室',
                    'Postal Code: 710021': '郵編：710021',
                    'Shanghai': '上海',
                    'HKQAA Certification (Shanghai) Ltd.': '標準認證服務(上海)有限公司',
                    'Unit 2007, 20/F., Pole Tower, 425 Yishan Road,': '中國上海市',
                    'Xuhui District, Shanghai, China.': '徐匯區宜山路425號光啟城20樓2007室',
                    'Postal Code: 200235': '郵編：200235',
                    'Macau': '澳門',
                    'HKQAA Certification (Macau) Ltd.': '標準認證服務(澳門)有限公司',
                    'Level 20, AIA Tower, Nos 251A-301 Avenida': '澳門商業',
                    'Comercial De Macau, Macau': '大馬路251A-301 號友邦廣場20樓',
                    'Mr.': '先生',
                    'Mrs.': '女士',
                    'Ms.': '小姐',
                    'Miss': '小姐',

                    //insights-and-trends.js
                    '"LightShare" is a pioneering knowledge-exchange online platform established by the Hong Kong Quality Assurance Agency (HKQAA). This platform brings together renowned organisation leaders, management professionals and industry experts to exchange experience, and share their international perspectives and invaluable knowledge with everyone in the world to facilitate capacity building and talent development.' : '「分享易」( LightShare ) 由香港品質保證局建立，是一個領先創新的網上知識交流平台，匯萃知名機構領袖、管理專才和行業專家，互相交流心得，並與世界各地人士分享國際視野和寶貴知識，促進人才及技能培訓。',
                    'Filter By': '篩選',
                    'By Date': '按日期',
                    'A to Z': 'A至Z',
                    'Z to A': 'Z至A',
                    'Showing': '顯示',
                    'Previous': '上一頁',
                    'Next': '下一頁',
                    'Rows per page': '每頁行數',
                    'Published': '已發布',

                    //courses/show.js
                    'Preview this course': '預覽課程',
                    'Course preview': '課程預覽',
                    'About this course': '關於課程',
                    'About trainer': '關於導師',
                    'Modules': '章節',
                    'Module': '章節',
                    'Trainer': '導師',
                    'Trainer Organization': '導師機構',
                    'Module No.': '章節',
                    'Class No.': '單元',
                    'Total Duration Hour': '總時數',
                    'Hours': '小時',
                    'Course Language': '課程語言',
                    'English': '英文',
                    'Traditional Chinese': '廣東話',
                    'Simple Chinese': '普通話',
                    'Quiz': '考試',
                    'Certificate': '證書',
                    'Material For Download': '資料下載',
                    'Download': '下載',
                    'Yes': '有',
                    'No': '沒有',
                    'Course Due Date': '課程截止日期',
                    'Course Fee': '課程費用',
                    'Free': '免費',
                    'Paid': '付費',
                    'HKD': '港幣',
                    'Enroll the course': '課程報名',
                    'Start the course': '開始課程',
                    'Pending...': '待辦中...',
                    //courses/show-module.js
                    'Class': '單元',
                    'No video uploaded for this class.....': '沒有為這單元上傳視頻......',
                    'Class Description': '單元描述',
                    'QUIZ': '考試',
                    'EVALUATION FORM': '課程滿意度調查問卷',
                    'Previous Class': '上一節',
                    'Next Class': '下一節',
                    //ClassItem.js
                    'Finished': '已完成',
                    'Pending': '待辦中',

                    //learner profile
                    'User not authorized! You will go back to Home Page.': '用戶未授權！ 您將返回主頁。',
                    'Saved successfully.': '保存成功。',
                    'Loading': '正在加載',
                    'Back to Home': '返回主頁',
                    'Edit': '編輯',
                    'Profile Information': '個人資料',
                    'Data Analysis': '數據分析',
                    'My Courses': '我的課程',
                    'My Certificates': '我的證書',
                    'Personal Information': '個人資料',
                    'Staff Information': '員工資料',
                    'Member Information': '會員資料',
                    'Username': '用戶名稱',
                    'Contact Name': '聯繫人姓名',
                    'Company email': '公司電郵',
                    'Association email': '協會電郵',
                    'Personal email': '個人電郵',
                    'Office Number': '辦公室號碼',
                    'Mobile Number': '手機號碼',
                    'Gender': '性別',
                    'Age group': '年齡階層',
                    'Education Level': '教育程度',
                    'Join elearning date': '加入日期',
                    'Staff ID': '員工編號',
                    'Membership No.': '會員編號',
                    'Company Name': '公司名稱',
                    'Department': '部門',
                    'Branch': '分行',
                    'Level': '級別',
                    'Position': '職位',
                    'Year Joined': '加入年份',
                    'Training Yearly Target': '培訓年度目標',
                    'Preferred Language': '首選語言',
                    'Status': '狀態',
                    'Interested Topics': '感興趣的類別',
                    'Completion Rate': '完成率',
                    'Yearly Target': '年度目標',
                    'courses': '課程',
                    'Training Completion Rate': '培訓完成率',
                    'In Progress': '進行中',
                    'You have not enrolled in any courses yet.': '您還沒有註冊任何課程',
                    'You have not registered for any courses yet.': '您還沒有註冊任何課程',
                    'Explore Courses': '探索課程',
                    'This course has expired.': '本課程已過期。',
                    'EXPIRED ON': '過期日期',
                    'View': '觀看',
                    'Course Code': '課程編號',
                    'Course Title': '課程名稱',
                    'Date Taken': '參與日期',
                    'Completion Date/ Date Completed': '完成日期',
                    'Watch Again': '再看一遍',
                    'Watch again': '再看一遍',
                    'Total Certificate': '張證書',
                    'Course Name': '課程名稱',
                    'Certificate Code': '證書編號',
                    'Given By': '給予者',
                    'Date': '日期',
                    'Actions': '操作',
                    'View All Certificates': '查看所有證書',
                    'Save': '保存',
                    'Cancel': '取消',
                    'Print':'列印',
                    'Pass':'合格',
                    'Fail':'不合格',

                    //UploadBox.js
                    'Drop file here or click to upload.': '將文件拖放到此處或點擊上傳。',
                    'Maximum upload file size:': '最大上傳文件大小:',
                    'File name must only contain alphanumeric characters and no spaces.': '文件名只能包含字母數字字符，不能包含空格。',
                    'Image aspect ratio: Square 1:1': '圖像縱橫比：正方形 1:1',
                    'Image ratio must be ': '圖像比例必須是 ',
                    'View the File': '查看文件',
                    'Upload Completed': '上傳完成',
                    'Upload Failed! Please try again.': '上傳失敗！ 請再試一次。',
                    'Delete': '刪除',

                    //signup
                    'Sign up': '登記',
                    'Log in': '登入',
                    'Account category': '賬戶類別',
                    'Choose your category': '選擇您的類別',
                    'Individual': '個人',
                    'Organization': '機構',
                    'Trainer/Academy': '導師/學院',
                    'Please fill in all fields to sign up.': '請填寫所有欄位以進行註冊。',
                    'Invalid Email': '無效的電郵',
                    'Password must be at least 8 characters long.': '密碼長度必須至少為 8 個字符。',
                    'Confirm Password is not equal to Password': '確認密碼與密碼不相同',
                    'Organization Name (same as B.R.)': '機構名稱 (與商業登記相同)',
                    'Trainer/Academy Name (same as B.R.)': '導師/學院名稱 (與商業登記相同)',
                    'Association Name (same as B.R.)': '協會組織名稱 (與商業登記相同)',
                    'Please upload your BR': '請上傳您的商業登記',
                    'Upload Your BR copy. Drag file here or click here': '上傳您的商業登記副本。 將文件拖至此處或單擊此處',
                    ' is uploaded successfully': '上傳成功',
                    'Back': '返回',
                    'Username can only use letters, numbers, underscores and periods.': '用戶名稱只能使用字母、數字、下劃線和句點。',
                    'Contact name': '聯繫人姓名',
                    'First name': '名字',
                    'Last name': '姓氏',
                    'Email address': '電郵',
                    'Password must be at least one upper character, at least one lower character, at least one digit and at least 8 characters.': '密碼必須至少是一個大寫字符、至少一個小寫字符、至少一個數字和至少 8 個字符。',
                    'Confirm Password': '確認密碼',
                    'By signing up, I agree to Lightshare\'s Privacy Policy and Terms of Use.': '註冊即表示我同意LightShare「分享易」的隱私政策和使用條款。',
                    'Note: If you click on the "Reactivate Account" button, the system will send you a new login link to your registered email address, allowing you to re-activate your account and access our platform again.': '注意: 如果您點擊「重新啟動用戶」按鈕, 系統將向您註冊的電郵地址發送一個新的登入連結, 讓您可以重新啟用您的帳戶和使用平台。',
                    'A new activation link has been sent to your email. Please check your inbox and click the link to reactivate your account.': '新的啟動用戶連結已經發送到您的電子郵件。請檢查您的收件匣並點擊新連結以重新啟用您的帳戶。',
                    'Reactivate Account': '重新啟動用戶',
                    'Confirm': '確認',
                    
                    //login.js
                    'Password': '密碼',
                    'keep me logged in': '保持登入狀態',
                    'LOG IN': '登入',
                    'Forgot password': '忘記密碼',
                    'Forgot username': '忘記用戶名稱',
                    'Please enter username and password': '請輸入用戶名稱和密碼',

                    //Forgot password
                    'Forget password': '忘記密碼',
                    'Please enter your username': '請輸入用戶名稱',
                    'Forgot Password': '忘記密碼',
                    'Forgot username?': '忘記用戶名稱?',

                    //Forget username
                    'Forget username': '忘記用戶名稱',
                    'Please enter your email': '請輸入您的電郵',
                    'Forgot Username': '忘記用戶名稱',
                    'Forgot password?': '忘記密碼?',

                    //Reset password
                    'Please enter your password': '請輸入您的密碼',

                    //Subscribe Newsletter
                    'Subscribe Newsletter': '訂閱最新資訊',
                    'Keep pace with market trends & visionary strategies': '緊貼市場趨勢及遠見策略',
                    'SUBSCRIBE NOW': '立即訂閱',
                    'Organisation Name': '機構名稱',
                    'Position': '職位',
                    'I argee to receive important updates and news from HKQAA LightShare including market trends, expert insights, hot courses, events and services, other educational and publicity information.': '我同意接收來自  LightShare「分享易」 的最新資訊，包括市場趨勢、專家見解、熱門課程、活動和服務，以及其他教育和宣傳信息。',
                   
                    //alert
                    'Are you sure to enroll this course?': '請問您是否確定報讀此課程？',
                    'The enrollment request for the course is well received and is now being processed. Please check your email with the enrollment details.': '我們已收妥你您的課程申請，現正在處理中 。有關報名詳情，請參閱電子郵件。',
                    'You do not have permission to enroll the course. Please use the Individual / Organization / Association account to enroll the course.': '抱歉，您沒有此課程的報讀權限 。請立即登錄/ 註冊個人用戶/ 機構用戶/ 協會組織用戶報讀課程。',
                    'You do not have permission to enroll the course. Please use the Individual account to enroll the course.': '抱歉，您沒有此課程的報讀權限 。請立即登錄/ 註冊個人用戶報讀課程。',                
                    'Quiz submitted successfully!': '課程考試已成功提交！',
                    'Congratulations! You had pass the quiz!': '恭喜！ 您已成功通過考試！',
                    'You can continue to Evaluation now.': '現在可以填寫課程滿意度調查問卷。',
                    'TAKE ME TO EVALUATION': '填寫課程滿意度調查問卷',
                    'GO TO PROFILE': '回到個人檔案',
                    ':( You have failed your quiz.': ':( 抱歉，您沒有通過課程考試。',
                    'No need to worry - you are welcome to retake the quiz.': '無需擔心 - 您有機會再次參加測驗。',
                    'You can retake the quiz ': '您可以重新考試',
                    ' more time.': '次。',
                    'RETAKE QUIZ': '重新考試',
                    'You cannot retake the quiz anymore.': '此課程沒有補考的安排，您不能重新考試。',
                    'Thank you for your time.': '感謝您的寶貴時間。',
                    'Please confirm the agreement.': '請確認協議。',
                    'Please enter all required fields': '請輸入所有必填欄位',
                    'Please complete the course before taking quiz.': '請完成課程後參加考試。',
                    'Please complete the course before taking evaluation form.': '請完成課程後填寫課程滿意度調查問卷。',
                    'No more remaining attempts.': '重考機會已使用完。',
                    'You haven’t finished the course yet! Cannot View Evaluation Form.': '你還沒有完成課程！不能填寫課程滿意度調查問卷。',
                    'You already have submitted the Evaluation Form for this course!': '您已提交了本課程的課程滿意度調查問卷！',
                    'Please answer all questions!': '請回答所有問題！',
                    'The username is already in use, please use another username.': '用戶名稱已被使用，請使用另一個用戶名稱。',
                    'Login Failed. Your account Status is Disable. Please contact your admin.': '登錄失敗。 您的帳戶狀態為禁用。 請聯繫您的管理員。',
                    'Login Failed. Your account Status is Terminated. Please contact HKQAA directly.': '登錄失敗。 您的帳戶已被終止。 請直接聯絡香港品質保證局。',
                    'Invalid login, please login again.': '登錄無效，請重新登錄。',
                    'Login Failed. username/password incorrect.': '登錄失敗。 用戶名稱/密碼不正確。',
                    'username not found!': '找不到此用戶名稱',
                    'email not found!': '找不到此電郵',
                    'The link is expired. Please submit the application again.': '連結已過期，請重新提交申請。',
                    'The link is invalid. Please submit the application again.': '連結無效，請重新提交申請。',
                    'Please upload the correct file type': '請上傳正確的文件類型',
                    'The file size should be less than 600MB': '文件大小應小於600MB',
                    'The file size should be less than 5MB': '文件大小應小於 5MB',
                    'Please check your email.': '請查看您電子郵件。',
                    'Staff info saved successfully.': '員工資料保存成功。',
                    'Member info saved successfully.': '會員資料保存成功。',
                    'Error cannot save Member Info.': '無法保存會員信息。',
                    'Enroll the course failed.': '註冊課程失敗。',
                    'Please input all fields!':'請輸入全部。',
                    'This course will expire within 7 days. Members are currently unable to enroll in this course.':'課程於7天內到期，會員現時無法註冊此課程。',
                    'Submitted':'提交',
                    'Note: We encourage you to review the course content and training videos before attempting the quiz once more. You may do so by clicking the button below to navigate to the course video page.': '備註: 我們鼓勵您在重考之前，先複習課程內容和培訓影片。您可以點擊下方的按鈕，進入您的個人主頁來完成複習課程。',
                    'View Course Video': '再次觀看課程影片',

                    //Gender
                    'Male': '男',
                    'Female': '女',

                    //Age
                    '61 and above': '61歲及以上',

                    //Education Level
                    'Primary School': '小學',
                    'High School': '中學',
                    'High Diploma': '高級文憑',
                    'Associate Degree': '副學士學位',
                    'Bachelor’s Degree': '學士學位',
                    'Master’s Degree': '碩士',
                    'Doctoral or Professional Degree': '博士或專業學位',

                    //Interested Topics and Course Category
                    'All Category': '所有類別',
                    'Sustainability': '可持續發展',
                    'Innovation': '創新',
                    'Capacity Building': '能力提升',
                    'Management': '管理',
                    'Green Finance': '綠色金融',
                    'Communication': '溝通技巧',
                    'ISO': 'ISO',
                    'Medical': '醫療',
                    'Pharmaceutical': '製藥',
                    'Greater Bay Area': '大灣區',
                    'Cosmetic': '化妝品',
                    'Construction': '建造業',
                    'Food & Beverage': '餐飲',
                    'Retail': '零售',
                    'Property Management': '物業管理',
                    'Elderly Care Service': '安老服務',
                    'Hotel': '酒店',
                    'Law and Legislation': '法律法規',
                    'I.T.': '資訊科技',
                    'Education': '教育',
                    'Service': '服務',
                    'Soft Skill': '軟技能',
                    'Other': '其他',

                    //Industry
                    'All Industry': '所有行業',
                    'Arts, entertainment and recreation': '藝術、娛樂及康樂活動',
                    'Catering': '餐飲業',
                    'Construction ': '建造業',
                    'Education ': '教育業',
                    'Elderly Care & Service Centre': '安老院舍和安老服務業',
                    'Electricity and gas supply': '電力及燃氣供應',
                    'Financial and insurance': '金融及保險業',
                    'Food and beverage': '食品與飲料業',
                    'Health and social work': '保健及社會工作業',
                    'Hotel ': '酒店業',
                    'Import/export, wholesale and retail trades': '進出口貿易、批發及零售業業',
                    'Information and communications': '資訊及通訊業',
                    'Innovation Technology': '創新科技業',
                    'Manufacturing': '製造業',
                    'NGO': '非牟利機構',
                    'Professional, business Consulting': '專業、業務諮詢業',
                    'Real estate and property management': '地產、物業管理業',
                    'Social enterprise': '社會企業',
                    'Transportation, storage and courier services': '運輸、倉庫及速遞服務業',
                    'Tourium': '旅遊業',
                    'Waste management & Recycling': '廢棄物管理及回收業',
                    'Service ': '服務業',
                    'Others': '其他行業',

                    //Department
                    'Customer Service': '客戶服務部',
                    'Design': '設計部',
                    'UI/UX': '用戶界面/ 用戶體驗設計部',
                    'Project Management': '項目管理部',
                    'Development': '發展部',
                    'Engineering': '工程部',
                    'Administrative': '行政部',
                    'Welfare': '福利部',
                    'Sales': '業務部',
                    'Marketing': '市場營銷部',
                    'Human Resources': '人力資源部',
                    'R&D': '研發部',
                    'Testing': '產品測試部',
                    'Finance': '財政部',
                    'Account': '會計部',
                    'Learning and Development': '學習與發展部',
                    'Quality': '品質管理部',
                    'Environmental': '環境保護部',
                    'Health & Safety': '健康及安全部',
                    'Promotion': '推廣部',
                    'I.T. Management': '資訊科技管理部',
                    'Secretary': '秘書處',
                    'Supporting Service': '服務支援部',
                    'Public Relation': '公共關係部',
                    'Legal': '法律事務部',
                    'Innovation ': '創新部',
                    'Transportation': '運輸部',
                    'Others ': '其他部門',

                    //Quiz
                    'Passing rate': '合格',
                    'Quiz retake': '重新考試',
                    'Remaining Quiz Attempts': '剩餘測驗次數',
                    'Please fill up below all questions and click submit quiz button.': '請填寫以下所有問題，然後單擊提交考試按鈕。',
                    'Question': '問題',
                    'SUBMIT QUIZ': '提交考試',
                    'time':'次',
                    'time(s)':'次',

                    //Evaluation Form
                    'Evaluation': '課程滿意度調查',
                    'Training Course Evaluation Questionnaire': '培訓課程評價問卷',
                    'SUBMIT': '提交',
                    'Additional Question':'反饋意見',
                    'Please type in here.':'請輸入',
                    'N/A':'不適用',
                    '(Learner will answer the evaluation form by rating from 1 - 7 & NA, with 7 being very satisfied and 1 being very dissatisfied.)':'（學員需按照滿意程度以 1 至 7 分或不適用回答問題，7 分代表非常滿意，1 分代表非常不滿意）',
                    'Feedback':'反饋',

                    //Evaluation Form Questions
                    'Course content was practical enough.': '課程內容有實用性',
                    'The coverage of the course was appropriate.': '課程的範圍適當',
                    'Handout materials were resourceful.': '講義能配合課程內容',
                    'Trainer was knowledgeable in subject discipline.': '導師對講題有充份了解及認識',
                    'Trainer possessed effective presentation and communication skills.': '導師有良好和有效的講解及溝通技巧',
                    'Trainer explained the course materials clearly.': '導師能夠解釋清楚講義內容',
                    'Appropriate examples presented in the course to help understanding.': '導師分享相關合適的例子，幫助學員了解內容',
                    'The trainer stimulated my interest in the course topic.': '導師激發了我對課程主題的興趣',
                    'Tempo was well controlled.': '導師對課程的進度控制得宜',
                    'Quality of course video and sound were right for purpose.': '影片和聲音的質量是良好和運作正常',
                    'Easy to navigate and manage the course on the platform.': '課程易於瀏覽和操作',
                    'Course and/or module was organized in a manner that is easy to follow.': '課程或單元內容易於理解',
                    'I was satisfied with the quality of this course.': '我滿意此課程的品質',
                    'This course would be helpful in my future endeavors.': '此課程對我未來的發展有所幫助',
                    'I would recommend this course to colleagues or business partners.': '我會向同事或商業夥伴推薦此課程',

                    //React data table component
                    'There are no records to display': '顯示沒有記錄',
                    'No certificates to display.': '顯示沒有證書',

                    //link
                    'LinkToHKQAA':'https://www.hkqaa.org/b5_index.php',
                    'HKQAAPersonnelSeries':'https://www.hkqaa.org/b5_regservice.php?catid=11&id=15',
                    "URL1":"http://www.hkqaa.org/b5_gsservice.php?catid=7&id=9",
                    "URL2":"https://srr.hkqaa.org/index.php?s=/Index/index/l/schinese.html",
                    "URL3":"https://hkwr.hkqaa.org/",
                    "URL4":"http://www.hkqaa.org/b5_abthkqaa.php?catid=5",
                    "URL5":"http://www.hkqaa.org/b5_community.php?catid=3",
                    "URL6":"http://www.hkqaa.org/b5_certservice.php?catid=26",

                }
            },
            SC: {
                translation: {
                    //Layout.js
                    ' ': '',
                    'Home': '主页', //Not Confirm
                    'Insights and Trends': '趋势与前瞻',
                    'Courses': '课程',
                    'Trainer / Academy': '导师/学院',
                    'Association': '协会组织',
                    'Membership': '用户类别',
                    'About Us': '关于我们',
                    'Personnel Registration': '人员注册',
                    'Contact Us': '联络我们',
                    'Login': '登入',
                    'Log In': '登入',
                    'Sign Up': '登记',
                    'Tel': '电话',
                    'Address: 19/F., K. Wah Centre, 191 Java Road, North Point, Hong Kong': '地址: 香港北角渣华道191号嘉华国际中心19楼',
                    'Email': '电邮',
                    'Subscribe our news': '订阅最新消息',
                    'Terms & Condition': '条款和条件',
                    'Terms And Condition': '条款和条件',
                    'Privacy Policy': '隐私和政策',
                    //ProfileLayout.js
                    'Log out': '登出',
                    'Welcome back': '欢迎回来,',
                    //Copyright.js
                    'Copyright': 'Copyright',
                    'Hong Kong Quality Assurance Agency.': '香港品质保证局',
                    'All rights reserved.': '版权所有 不得转载',
                    //Home
                    'LIGHTSHARE': 'LightShare「分享易」',
                    'KNOWLEDGE EXCHANGE PLATFORM': '知识交流平台',
                    'INSPIRING': '启迪视野',
                    'SHARING': '知识分享',
                    'NETWORKING': '互动连结',
                    'EXPLORE MORE': '查看更多',
                    'Insights And Trends': '趋势与前瞻',
                    'Stay ahead of megatrends with visionary perspectives': '开拓宏远视野，走在大趋势前端',
                    //
                    'DISCOVER MORE': '查看更多',
                    'Search': '搜寻',
                    'Profile / Spotlight': '简介/焦点',
                    'Pick the potential trainer/academy and review their offer': '选择合适您的导师/学院，并查看相关的课程',
                    'Pick the potential association and review their offer': '选择合适您的协会组织，并查看相关的课程', 
                    'How We Work?': '如何运作？ ',
                    'Let\'s get started.': '我们马上开始',
                    'Individual Member': '个人用户',
                    'Can through this platform to join the hottest topic training and receive the insight and trends message. Enhancing of the competence with timeless plan.': '可透过 LightShare平台参与最新和热门的培训课程，提升个人竞争力。 ',
                    'Organization Member': '机构用户',
                    'Can establish their own E-Learning Platform for supporting their own staff to enhance the competence and skill with timeless plan. They can select the training topic from the platform or use their own training material (Video) to upload to the platform and share to the specific group of staff then management with training analysis report.': '可透过 LightShare平台建立机构内部的网上培训平台，LightShare提供不同主题的培训内容可供选择，而机构更可上载自家量身定制的培训课程和短片，通过平台一站式提供培训予员工及管理培圳记录。 ',
                    'Trainer and Academy Member': '导师/学院用户',
                    'Can through this platform to share & promote their training course, also this platform is providing the participant analysis report.': '可透过 LightShare平台分享和推广培训课程。 ',
                    'Association Member': '协会组织用户',
                    'Can through this platform to share & promote their training course to their specific member, also this platform is providing the participant analysis report.': '可透过LightShare平台向他们的特定会员分享和推广他们的培训课程，通过平台也提供参与者分析报告。 ',
                    'Joining the course': '参加课程',
                    'Step: 1': '第一步',
                    'Registration': '登记成为用户',
                    'Click the link and create your username & password then you will become a member right away.': '点击链结即可建立您的用户名称和密码，您将立即成为会员。 ',
                    'Step: 2': '第二步',
                    'Select the course': '选择课程',
                    'Select the interesting course and apply it anytime, anywhere.': '随时随地选择及申请感兴趣的课程。',
                    'Step: 3': '第三步',
                    'Receive the token': '收到网上代币',
                    'Trainer will assign the token number for you to join the specific course.': '选择您感兴趣的课程，随时随地应用。 ',
                    'Step: 4': '第四步',
                    'Join the course': '参加课程',
                    'Member can join the course.': '会员可以参与课程。 ',
                    'Inspiring, Sharing and Networking': '启迪视野，知识分享，互动连结',
                    'Sign up free today!': '立即免费登记',
                    'SIGN UP': '登记',
                    'HKQAA Services and Campaigns': '香港品质保证局的服务和活动',
                    //Trainer/Academy
                    'Highlight Trainer / Academy': '主导师/焦点学院',
                    'Latest Trainer / Academy': '最新导师/学院',
                    //Membership
                    'Lightshare Membership': 'LightShare「分享易」用户类型',
                    'Lightshare platform is offering four different finds of memberships to our society, which included': ' LightShare分享易平台提供四种不同的用户类型，其中包括：',
                    'Membership_Content1': '参与者通过参与',
                    'Individual member': '个人用户',
                    'is supporting you to enhance your personal knowledge & social network,': '，增进个人知识和扩大社交网络。 平台不仅支持机构用户提升内部员工的个人能力和知识，',
                    'Organization membership': '机构用户',
                    'is supporting all kind of organization to enhance their staff competence and knowledge also the organization can through this platform to share their knowledge and technology to the potential partner.': '更可通过这个平台与潜在合作伙伴分享他们的知识和技术。',
                    'Trainer & Academy membership': '导师/学院',
                    'is supporting the individual trainer or academy through the lightshare platform to reach their potential client. It is low-cost tools to enhancing their business performance during the pandemic period.': '通过平台联系潜在客户，全方位及成本相宜地提升其业务绩效。',
                    'Association membership': '协会用户',
                    'is going to support the industry association to share their industry news or knowledge to the stakeholder.': '可与行业协会向持分者分享其行业新闻或知识。',
                    'Individual Membership': '个人用户',
                    'Can through this platform to join the hottest topic training and receive the insight and trends message. Enhancing of the competence with timeless plan.': '可透过 LightShare「分享易」平台一起参与热门的网上培训课程，掌握最新市场趋势和关键信息，随时随地提升个人竞争力。',
                    'Apply Now': '立即申请',
                    'Organization Membership': '机构用户',
                    'Can establish their own E-Learning Platform for supporting their own staff to enhance the competence and skill with timeless plan. They can select the training topic from the platform or use their own training material (Video) to upload to the platform and share to the specific group of staff then management with training analysis report.': '可透过 LightShare「分享易」平台建立机构内部的网上培训平台。 机构可选择平台上不同主题的培训内容，更可上载自家量身定制的培训课程和短片，通过平台一站式服务提供培训给予员工及获得管理培训记录。',
                    'Trainer/Academy Membership': '导师/学院用户',
                    'Can through this platform to share & promote their training course, also this platform is providing the participant analysis report.': '可透过 LightShare分享易平台分享及推广培训课程给予登记用户“，配以参与者分析报告。',
                    'Coming Soon': '简Coming Soon',
                    'Association Membership': '协会组织用户',
                    'Can through this platform to share & promote their training course to their specific member, also this platform is providing the participant analysis report.': '可透过LightShare分享易平台分享及推广培训课程给予登记用户，配以参与者分析报告',
                    //About Us
                    'About LightShare': '关于LightShare「分享易」',
                    'Through the LightShare, you are also able to become a part of the meta-networking ecosystem, which enables you to extend your business network and raise market awareness across regions, and build your socially responsible image in the communities.': '通過這平台，你還可以成為元網絡生態系統的一部分，助你跨越地域及界別限制，擴展業務網絡和增加市場知名度，提升社會責任形象。',
                    'You are welcome to join us as a member of "LightShare" and explore the tremendous development opportunities together!': '欢迎您加入成为“LightShare”的登记用户，共同探索巨大的发展机遇！',
                    'About HKQAA': '关于香港品质保证局',
                    'Established in 1989 as a non-profit public organisation by the Hong Kong Government, HKQAA is committed to promoting good management practices.': '香港质量保证局是由香港政府于1989年成立的非牟利机构，致力于推广良好的管理方法。',
                    'Through knowledge sharing and technology transfer, we help industries enhance their management performance. We also provide innovative and professional services to support enterprises and society to address future challenges and foster sustainable development.': '我们通过知识共享和技术转换，帮助行业提高管理绩效。 我们还提供创新和专业服务，支持企业和社会应对未来挑战，促进可持续发展。',
                    'HKQAA is devoted to creating value for enterprises and making contributions to shaping a better environment and society. We have developed a wide range of pioneering services to meet the needs of industry and society. These services are in the areas of social responsibility, environmental protection, energy management, carbon neutrality, green and sustainable finance, and barrier free accessibility management.': ' HKQAA致力于为企业创造价值，并为创造更好的环境和社会作出贡献。 我们开发了一系列创性服务，以满足行业和社会的需求。 这些服务涉及社会责任、环境保护、能源管理、碳中和、绿色和可持续金融以及无障碍管理等领域。',
                    //Personnel Registration Service ----Not Confirm
                    'Personnel Registration Service': '人员注册服务',
                    '"LightShare" is a pioneering knowledge-exchange online platform established by the Hong Kong Quality Assurance Agency (HKQAA).': 'LightShare 「分享易」由香港品质保证局建立，是一个领先创新的网上知识交流平台。',
                    'This platform brings together renowned organisation leaders, management professionals and industry experts to exchange experience, and share their international perspectives and invaluable knowledge with everyone in the world to facilitate capacity building and talent development.': '平台汇萃知名机构领袖、管理专才和行业专家，互相交流心得，并与世界各地人士分享国际视野和宝贵知识，促进人才及技能培训。',
                    'You are welcome to join us as a member of "LightShare" and explore the tremendous development opportunities together!': '欢迎您加入成为“LightShare”的登记用户，共同探索巨大的发展机遇！',
                    //Contact Us ----Not Confirm
                    'If you have any enquiries, please complete the below e-form. We will be contacting you later': '如有任何查询，请填写以下网上表格，我们将会与您联络。',
                    'Title': '称谓',
                    'First Name': '名字',
                    'Last Name': '姓氏',
                    'Email': '电邮',
                    'Country/Region': '国家/地区',
                    'Message': '信息',
                    'Submit': '提交',
                    'Office Addresses': '各地办公地址',
                    'Hong Kong': '香港',
                    'Hong Kong Quality Assurance Agency': '香港品质保证局',
                    '19 / F., K. Wah Centre, 191 Java Road,': '香港北角',
                    'North Point, Hong Kong': '渣华道191号嘉华国际中心19楼',
                    'Guangzhou': '广州',
                    'HKQAA Certification (Shanghai) Ltd.': '标准认证服务(上海)有限公司',
                    'Guangzhou Branch': '广州分公司',
                    '22 / F., Nova Tower, No. 185 Yue Xiu Road South,': '中国广州市',
                    'Guangzhou, China.': '越秀南路185号创举商务大厦22楼',
                    'Postal Code: 510100': '邮编：510100',
                    'Xi’an': '西安',
                    'HKQAA Certification (Shanghai) Ltd.': '标准认证服务(上海)有限公司',
                    'Xian Representative Office': '西安办事处',
                    'Room 1608, 16/F., New World, 70 Weibin Road,': '中国陕西省西安市',
                    'Weiyang District, Xi’an City, Shanxi Province, China': '未央区渭滨路70号水晶新天地16楼1608室',
                    'Postal Code: 710021': '邮编：710021',
                    'Shanghai': '上海',
                    'HKQAA Certification (Shanghai) Ltd.': '标准认证服务(上海)有限公司',
                    'Unit 2007, 20/F., Pole Tower, 425 Yishan Road,': '中国上海市',
                    'Xuhui District, Shanghai, China.': '徐汇区宜山路425号光启城20楼2007室',
                    'Postal Code: 200235': '邮编：200235',
                    'Macau': '澳门',
                    'HKQAA Certification (Macau) Ltd.': '标准认证服务(澳门)有限公司',
                    'Level 20, AIA Tower, Nos 251A-301 Avenida': '澳门商业',
                    'Comercial De Macau, Macau': '大马路251A-301 号友邦广场20楼',
                    'Mr.': '先生',
                    'Mrs.': '女士',
                    'Ms.': '小姐',
                    'Miss': '小姐',

                    //insights-and-trends/index.js
                    '"LightShare" is a pioneering knowledge-exchange online platform established by the Hong Kong Quality Assurance Agency (HKQAA). This platform brings together renowned organisation leaders, management professionals and industry experts to exchange experience, and share their international perspectives and invaluable knowledge with everyone in the world to facilitate capacity building and talent development.' : '「分享易」( LightShare ) 由香港品质保证局建立，是一个领先创新的网上知识交流平台，汇萃知名机构领袖、管理专才和行业专家，互相交流心得，并与世界各地人士分享国际视野和宝贵知识，促进人才及技能培训。',
                    'Filter By': '筛选',
                    'By Date': '按日期',
                    'A to Z': 'A至Z',
                    'Z to A': 'Z至A',
                    'Showing': '显示',
                    'Previous': '上一页',
                    'Next': '下一页',
                    'Rows per page': '每页行数',
                    //insights-and-trends/show.js
                    'Published': '已发布',

                    //courses/show.js
                    'Preview this course': '预览课程',
                    'Course preview': '课程预览',
                    'About this course': '关于课程',
                    'About trainer': '关于导师',
                    'Modules': '章节',
                    'Module': '章节',
                    'Trainer': '导师',
                    'Trainer Organization': '导师机构',
                    'Module No.': '章节',
                    'Class No.': '单元',
                    'Total Duration Hour': '总时数',
                    'Hours': '小时',
                    'Course Language': '课程语言',
                    'English': '英文',
                    'Traditional Chinese': '广东话',
                    'Simple Chinese': '普通话',
                    'Quiz': '考试',
                    'Certificate': '证书',
                    'Material For Download': '资料下载',
                    'Download': '下载',
                    'Yes': '有',
                    'No': '没有',
                    'Course Due Date': '课程截止日期',
                    'Course Fee': '课程费用',
                    'Free': '免费',
                    'Paid': '付费',
                    'HKD': '港币',
                    'Enroll the course': '课程报名',
                    'Start the course': '开始课程',
                    'Pending...': '待办中...',
                    //courses/show-module.js
                    'Class': '单元',
                    'No video uploaded for this class.....': '没有为这单元上传视频......',
                    'Class Description': '单元描述',
                    'QUIZ': '考试',
                    'EVALUATION FORM': '课程满意度调查问卷',
                    'Previous Class': '上一节',
                    'Next Class': '下一节',
                    //ClassItem.js
                    'Finished': '已完成',
                    'Pending': '待办中',

                    //learner profile
                    'User not authorized! You will go back to Home Page.': '用户未授权！您将返回主页。 ',
                    'Saved successfully.': '保存成功。 ',
                    'Loading': '正在加载',
                    'Back to Home': '返回主页',
                    'Edit': '编辑',
                    'Profile Information': '个人资料',
                    'Data Analysis': '数据分析',
                    'My Courses': '我的课程',
                    'My Certificates': '我的证书',
                    'Personal Information': '个人资料',
                    'Staff Information': '员工资料',
                    'Member Information': '会员资料',
                    'Username': '用户名称',
                    'Contact Name': '联系人姓名',
                    'Company email': '公司电邮',
                    'Association email': '协会电邮',
                    'Personal email': '个人电邮',
                    'Office Number': '办公室号码',
                    'Mobile Number': '手机号码',
                    'Gender': '性别',
                    'Age group': '年龄阶层',
                    'Education Level': '教育程度',
                    'Join elearning date': '加入日期',
                    'Staff ID': '员工编号',
                    'Membership No.': '会员编号',
                    'Company Name': '公司名称',
                    'Department': '部门',
                    'Branch': '分行',
                    'Level': '级别',
                    'Position': '职位',
                    'Year Joined': '加入年份',
                    'Training Yearly Target': '培训年度目标',
                    'Preferred Language': '首选语言',
                    'Status': '状态',
                    'Interested Topics': '感兴趣的类别',
                    'Completion Rate': '完成率',
                    'Yearly Target': '年度目标',
                    'courses': '课程',
                    'Training Completion Rate': '培训完成率',
                    'In Progress': '进行中',
                    'You have not enrolled in any courses yet.': '您还没有注册任何课程',
                    'You have not registered for any courses yet.': '您还没有注册任何课程',
                    'Explore Courses': '探索课程',
                    'This course has expired.': '本课程已过期。 ',
                    'EXPIRED ON': '过期日期',
                    'View': '观看',
                    'Course Code': '课程编号',
                    'Course Title': '课程名称',
                    'Date Taken': '参与日期',
                    'Completion Date/ Date Completed': '完成日期',
                    'Watch Again': '再看一遍',
                    'Watch again': '再看一遍',
                    'Total Certificate': '张证书',
                    'Course Name': '课程名称',
                    'Certificate Code': '证书编号',
                    'Given By': '给予者',
                    'Date': '日期',
                    'Actions': '操作',
                    'View All Certificates': '查看所有证书',
                    'Save': '保存',
                    'Cancel': '取消',
                    'Print':'列印',
                    'Pass':'合格',
                    'Fail':'不合格',
                    
                    //UploadBox.js
                    'Drop file here or click to upload.': '将文件拖放到此处或点击上传。',
                    'Maximum upload file size:': '最大上传文件大小:',
                    'File name must only contain alphanumeric characters and no spaces.': '文件名只能包含字母数字字符，不能包含空格。',
                    'Image aspect ratio: Square 1:1': '图像纵横比：正方形 1:1',
                    'Image ratio must be ': '图像比例必须是 ',
                    'View the File': '查看文件',
                    'Upload Completed': '上传完成',
                    'Upload Failed! Please try again.': '上传失败！ 请再试一次。',
                    'Delete': '删除',

                    //signup
                    'Sign up': '登记',
                    'Log in': '登入',
                    'Account category': '账户类别',
                    'Choose your category': '选择您的类别',
                    'Individual': '个人',
                    'Organization': '机构',
                    'Trainer/Academy': '导师/学院',
                    'Please fill in all fields to sign up.': '请填写所有栏位以进行注册。',
                    'Invalid Email': '无效的电邮',
                    'Password must be at least 8 characters long.': '密码长度必须至少为 8 个字符。',
                    'Confirm Password is not equal to Password': '确认密码与密码不相同',
                    'Organization Name (same as B.R.)': '机构名称 (与商业登记相同)',
                    'Trainer/Academy Name (same as B.R.)': '导师/学院名称 (与商业登记相同)',
                    'Association Name (same as B.R.)': '协会组织名称 (与商业登记相同)',
                    'Please upload your BR': '请上传您的商业登记',
                    'Upload Your BR copy. Drag file here or click here': '上传您的商业登记副本。 将文件拖至此处或单击此处',
                    ' is uploaded successfully': '上传成功',
                    'Back': '返回',
                    'Username can only use letters, numbers, underscores and periods.': '用户名称只能使用字母、数字、下划线和句点。',
                    'Contact name': '联系人姓名',
                    'First name': '名字',
                    'Last name': '姓氏',
                    'Email address': '电邮',
                    'Password must be at least one upper character, at least one lower character, at least one digit and at least 8 characters.': '密码必须至少是一个大写字符、至少一个小写字符、至少一个数字和至少 8 个字符。',
                    'Confirm Password': '确认密码',
                    'By signing up, I agree to Lightshare\'s Privacy Policy and Terms of Use.': '注册即表示我同意LightShare「分享易」的隐私政策和使用条款。',
                    'Note: If you click on the "Reactivate Account" button, the system will send you a new login link to your registered email address, allowing you to re-activate your account and access our platform again.': '注意: 如果您点击「重新启动用户」按钮, 系统将向您注册的电邮地址发送一个新的登入连结, 让您可以重新启用您的帐户和使用平台。',
                    'A new activation link has been sent to your email. Please check your inbox and click the link to reactivate your account.': '新的启动用户连结已经发送到您的电子邮件。请检查您的收件匣并点击新连结以重新启用您的帐户。',
                    'Reactivate Account': '重新启动用户',
                    'Confirm': '确认',

                    //login.js
                    'Password': '密码',
                    'keep me logged in': '保持登入状态',
                    'LOG IN': '登入',
                    'Forgot password': '忘记密码',
                    'Forgot username': '忘记用户名称',
                    'Please enter username and password': '请输入用户名和密码',

                    //Forgot password
                    'Forget password': '忘记密码',
                    'Please enter your username': '请输入用户名称',
                    'Forgot Password': '忘记密码',
                    'Forgot username?': '忘记用户名称?',

                    //Forget username
                    'Forget username': '忘记用户名称',
                    'Please enter your email': '请输入您的电邮',
                    'Forgot Username': '忘记用户名称',
                    'Forgot password?': '忘记密码?',

                    //Reset password
                    'Please enter your password': '请输入您的密码',

                    //Subscribe Newsletter
                    'Subscribe Newsletter': '订阅最新资讯',
                    'Keep pace with market trends & visionary strategies': '紧贴市场趋势及远见策略',
                    'SUBSCRIBE NOW': '立即订阅',
                    'Organisation Name': '机构名称',
                    'Position': '职位',
                    'I argee to receive important updates and news from HKQAA LightShare including market trends, expert insights, hot courses, events and services, other educational and publicity information.': '我同意接收来自  LightShare「分享易」 的最新资讯，包括市场趋势、专家见解、热门课程、活动和服务，以及其他教育和宣传信息。',
                     
                    //alert
                    'Are you sure to enroll this course?': '请问您是否确定报读此课程？',
                    'The enrollment request for the course is well received and is now being processed. Please check your email with the enrollment details.': '我们已收妥你您的课程申请，现正在处理中 。有关报名详情，请参阅电子邮件。',
                    'You do not have permission to enroll the course. Please use the Individual / Organization / Association account to enroll the course.': '抱歉，您没有此课程的报读权限 。请立即登录/ 注册个人用户/ 机构用户/ 协会组织用户报读课程。',
                    'You do not have permission to enroll the course. Please use the Individual account to enroll the course.': '抱歉，您没有此课程的报读权限 。请立即登录/ 注册个人用户报读课程。',                
                    'Quiz submitted successfully!': '课程考试已成功提交！',
                    'Congratulations! You had pass the quiz!': '恭喜！您已成功通过考试！',
                    'You can continue to Evaluation now.': '现在可以填写课程满意度调查问卷。',
                    'TAKE ME TO EVALUATION': '填写课程满意度调查问卷',
                    'GO TO PROFILE': '回到个人档案',
                    ':( You have failed your quiz.': ':( 抱歉，您没有通过课程考试。',
                    'No need to worry - you are welcome to retake the quiz.': '无需担心 - 您有机会再次参加测验。',
                    'You can retake the quiz ': '您可以重新考试',
                    ' more time.': '次。',
                    'RETAKE QUIZ': '重新考试',
                    'You cannot retake the quiz anymore.': '此课程没有补考的安排，您不能重新考试。',
                    'Thank you for your time.': '感谢您的宝贵时间。',
                    'Please confirm the agreement.': '请确认协议。 ',
                    'Please enter all required fields': '请输入所有必填栏位',
                    'Please complete the course before taking quiz.': '请完成课程后参加考试。',
                    'Please complete the course before taking evaluation form.': '请完成课程后填写课程满意度调查问卷。',
                    'No more remaining attempts.': '重考机会已使用完。',
                    'You haven’t finished the course yet! Cannot View Evaluation Form.': '你还没有完成课程！不能填写课程满意度调查问卷。',
                    'You already have submitted the Evaluation Form for this course!': '您已提交了本课程的课程满意度调查问卷！',
                    'Please answer all questions!': '请回答所有问题！',
                    'The username is already in use, please use another username.': '用户名称已被使用，请使用另一个用户名称。',
                    'Login Failed. Your account Status is Disable. Please contact your admin.': '登录失败。您的帐户状态为禁用。请联系您的管理员。',
                    'Login Failed. Your account Status is Terminated. Please contact HKQAA directly.': '登录失败。您的帐户已被终止。请直接联络香港品质保证局。',
                    'Invalid login, please login again.': '登录无效，请重新登录。',
                    'Login Failed. username/password incorrect.': '登录失败。用户名称/密码不正确。',
                    'username not found!': '找不到此用户名称',
                    'email not found!': '找不到此电邮',
                    'The link is expired. Please submit the application again.': '连结已过期，请重新提交申请。',
                    'The link is invalid. Please submit the application again.': '连结无效，请重新提交申请。',
                    'Please upload the correct file type': '请上传正确的文件类型',
                    'The file size should be less than 600MB': '文件大小应小于600MB',
                    'The file size should be less than 5MB': '文件大小应小于5MB',
                    'Please check your email.': '请查看您电子邮件。',
                    'Staff info saved successfully.': '员工资料保存成功。',
                    'Member info saved successfully.': '会员资料保存成功。',
                    'Error cannot save Member Info.': '无法保存会员信息。',
                    'Enroll the course failed.': '注册课程失败。',
                    'Please input all fields!':'请输入全部。',
                    'This course will expire within 7 days. Members are currently unable to enroll in this course.':'课程于7天内到期，会员现时无法注册此课程。',
                    'Submitted':'提交',
                    'Note: We encourage you to review the course content and training videos before attempting the quiz once more. You may do so by clicking the button below to navigate to the course video page.': '备注: 我们鼓励您在重考之前，先复习课程内容和培训影片。您可以点击下方的按钮，进入您的个人主页来完成复习课程。',
                    'View Course Video': '再次观看课程影片',
                    
                    //Gender
                    'Male': '男',
                    'Female': '女',

                    //Age
                    '61 and above': '61岁及以上',

                    //Education Level
                    'Primary School': '小学',
                    'High School': '中学',
                    'High Diploma': '高级文凭',
                    'Associate Degree': '副学士学位',
                    "Bachelor’s Degree": '学士学位',
                    "Master’s Degree": '硕士',
                    'Doctoral or Professional Degree': '博士或专业学位',

                    //Interested Topics and Course Category
                    'All Category': '所有类别',
                    'Sustainability': '可持续发展',
                    'Innovation': '创新',
                    'Capacity Building': '能力提升',
                    'Management': '管理',
                    'Green Finance': '绿色金融',
                    'Communication': '沟通技巧',
                    'ISO': 'ISO',
                    'Medical': '医疗',
                    'Pharmaceutical': '制药',
                    'Greater Bay Area': '大湾区',
                    'Cosmetic': '化妆品',
                    'Construction': '建造业',
                    'Food & Beverage': '餐饮',
                    'Retail': '零售',
                    'Property Management': '物业管理',
                    'Elderly Care Service': '安老服务',
                    'Hotel': '酒店',
                    'Law and Legislation': '法律法规',
                    'I.T.': '资讯科技',
                    'Education': '教育',
                    'Service': '服务',
                    'Soft Skill': '软技能',
                    'Other': '其他',

                    //Industry
                    'All Industry': '所有行业',
                    'Arts, entertainment and recreation': '艺术、娱乐及康乐活动',
                    'Catering': '餐饮业',
                    'Construction ': '建造业',
                    'Education ': '教育业',
                    'Elderly Care & Service Centre': '安老院舍和安老服务业',
                    'Electricity and gas supply': '电力及燃气供应',
                    'Financial and insurance': '金融及保险业',
                    'Food and beverage': '食品与饮料业',
                    'Health and social work': '保健及社会工作业',
                    'Hotel ': '酒店业',
                    'Import/export, wholesale and retail trades': '进出口贸易、批发及零售业业',
                    'Information and communications': '资讯及通讯业',
                    'Innovation Technology': '创新科技业',
                    'Manufacturing': '制造业',
                    'NGO': '非牟利机构',
                    'Professional, business Consulting': '专业、业务咨询业',
                    'Real estate and property management': '地产、物业管理业',
                    'Social enterprise': '社会企业',
                    'Transportation, storage and courier services': '运输、仓库及速递服务业',
                    'Tourium': '旅游业',
                    'Waste management & Recycling': '废弃物管理及回收业',
                    'Service ': '服务业',
                    'Others': '其他行业',

                    //Department
                    'Customer Service': '客户服务部',
                    'Design': '设计部',
                    'UI/UX': '用户界面/ 用户体验设计部',
                    'Project Management': '项目管理部',
                    'Development': '发展部',
                    'Engineering': '工程部',
                    'Administrative': '行政部',
                    'Welfare': '福利部',
                    'Sales': '业务部',
                    'Marketing': '市场营销部',
                    'Human Resources': '人力资源部',
                    'R&D': '研发部',
                    'Testing': '产品测试部',
                    'Finance': '财政部',
                    'Account': '会计部',
                    'Learning and Development': '学习与发展部',
                    'Quality': '品质管理部',
                    'Environmental': '环境保护部',
                    'Health & Safety': '健康及安全部',
                    'Promotion': '推广部',
                    'I.T. Management': '资讯科技管理部',
                    'Secretary': '秘书处',
                    'Supporting Service': '服务支援部',
                    'Public Relation': '公共关系部',
                    'Legal': '法律事务部',
                    'Innovation ': '创新部',
                    'Transportation': '运输部',
                    'Others ': '其他部门',

                    //Quiz
                    'Passing rate': '合格',
                    'Quiz retake': '重新考试',
                    'Remaining Quiz Attempts': '剩余测验次数',
                    'Please fill up below all questions and click submit quiz button.': '请填写以下所有问题，然后单击提交考试按钮。',
                    'Question': '问题',
                    'SUBMIT QUIZ': '提交考试',
                    'time':'次',
                    'time(s)':'次',

                    //Evaluation Form
                    'Evaluation': '课程满意度调查',
                    'Training Course Evaluation Questionnaire': '培训课程评价问卷',
                    'SUBMIT': '提交',
                    'Additional Question':'反馈意见',
                    'Please type in here.':'请输入',
                    'N/A':'不适用',
                    '(Learner will answer the evaluation form by rating from 1 - 7 & NA, with 7 being very satisfied and 1 being very dissatisfied.)':'（学员需按照满意程度以 1 至 7 分或不适用回答问题，7 分代表非常满意，1 分代表非常不满意）',
                    'Feedback':'反馈',

                    //Evaluation Form Questions
                    'Course content was practical enough.': '课程内容有实用性',
                    'The coverage of the course was appropriate.': '课程的范围适当',
                    'Handout materials were resourceful.': '讲义能配合课程内容',
                    'Trainer was knowledgeable in subject discipline.': '导师对讲题有充份了解及认识',
                    'Trainer possessed effective presentation and communication skills.': '导师有良好和有效的讲解及沟通技巧',
                    'Trainer explained the course materials clearly.': '导师能够解释清楚讲义内容',
                    'Appropriate examples presented in the course to help understanding.': '导师分享相关合适的例子，帮助学员了解内容',
                    'The trainer stimulated my interest in the course topic.': '导师激发了我对课程主题的兴趣',
                    'Tempo was well controlled.': '导师对课程的进度控制得宜',
                    'Quality of course video and sound were right for purpose.': '影片和声音的质量是良好和运作正常',
                    'Easy to navigate and manage the course on the platform.': '课程易于浏览和操作',
                    'Course and/or module was organized in a manner that is easy to follow.': '课程或单元内容易于理解',
                    'I was satisfied with the quality of this course.': '我满意此课程的品质',
                    'This course would be helpful in my future endeavors.': '此课程对我未来的发展有所帮助',
                    'I would recommend this course to colleagues or business partners.': '我会向同事或商业伙伴推荐此课程',

                    //React data table component
                    'There are no records to display': '显示没有记录',
                    'No certificates to display.': '显示没有证书',

                    //link
                    'LinkToHKQAA':'https://www.hkqaa.org/gb_index.php',
                    'HKQAAPersonnelSeries':'https://www.hkqaa.org/gb_regservice.php?catid=11&id=15',
                    "URL1":"http://www.hkqaa.org/gb_gsservice.php?catid=7&id=9",
                    "URL2":"https://srr.hkqaa.org/index.php?s=/Index/index/l/schinese.html",
                    "URL3":"https://hkwr.hkqaa.org/",
                    "URL4":"http://www.hkqaa.org/gb_abthkqaa.php?catid=5",
                    "URL5":"http://www.hkqaa.org/gb_community.php?catid=3",
                    "URL6":"http://www.hkqaa.org/gb_certservice.php?catid=26",

                }
            },
        },
        lng: localStorage.getItem('i18nLanguage') || "EN",
        fallbackLng: localStorage.getItem('i18nLanguage') || "EN",

        interpolation: {
          escapeValue: false,
        }
    });
    
export default i18n;